// Here you can add other styles

.c-sidebar .c-sidebar-brand {
  // background-image: linear-gradient(
  //   to right,
  //   #0d3e64,
  //   #682666,
  //   #e6004d
  // ) !important;
  // background-color: #fff;
  background-color: rgba(0, 0, 21, 0.2);
  // background: #fff;
}
.c-sidebar.c-sidebar-light .c-sidebar-brand {
  // color: #fff;
  background: #fff;
}

.c-sidebar-nav-item-sub {
  padding-left: 8px;
  font-size: 13px;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  color: #0d3e64;
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #0d3e64;
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #0d3e64;
}

.table th {
  white-space: nowrap;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
  background-color: #0d3e64 !important;
  color: #fff !important;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #fff !important;
}

.c-switch-primary .c-switch-input:checked + .c-switch-slider {
  background-color: #00aec7;
  border-color: #00aec7;
}

.btn-primary {
  color: #fff;
  background-color: #00aec7 !important;
  border-color: #00aec7 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d3e64;
}
.btn-link {
  font-weight: 400;
  color: #00aec7 !important;
  text-decoration: none;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #0d3e64;
}

html:not([dir="rtl"]) .form-check-input {
  margin-left: 0rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00aec7;
  background-color: #00aec7;
}

.bg-gradient-custom-1925 {
  background: #e0004e !important;
  // background: linear-gradient(45deg, #321fdb 0%, #e0004e 100%) !important;
  border-color: #e0004e !important;
}

.bg-gradient-custom-260 {
  background: #642667 !important;
  // background: linear-gradient(45deg, #321fdb 0%, #642667 100%) !important;
  border-color: #642667 !important;
}

.bg-gradient-custom-541 {
  background: $primary-color !important;
  // background: linear-gradient(45deg, #321fdb 0%, $primary-color 100%) !important;
  border-color: $primary-color !important;
}

.text-primary {
  color: $primary-color !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: $primary-color !important;
  color: white;
}

.nav-tabs .nav-link:hover {
  color: $primary-color !important;
}

.nav-tabs .nav-link.active:hover {
  color: white !important;
}
.border-primary {
  border-color: $primary-color !important;
}
.card-header-primary {
  header {
    background-color: $primary-color !important;
    color: white;
    font-weight: bold;
    font-size: 18px;
  }
}

header.card-header-primary {
  background-color: $primary-color !important;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.modal-header {
  .close {
    color: white;
  }
}

.navs-custom {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
}

.navs-custom.active {
  background: #0d3e64 !important;
  cursor: pointer;
  color: white;
}

.is-error {
  color: #e0004e;
  margin-top: 10px;
}

.input-error {
  background-color: #ffcccc !important;
}

.modal-xl{
  max-width: 75%;
}

.c-switch-slider {
	background-color: #d8dbe0;
}

td a.link {
	text-decoration: underline;
	color: #321fdb;
}
.bg-blue {
  background-color: #12284c;
  border-radius: 0px 4px 4px 0px;
}

.btn-warning-custom {
  background: #e35929;
  box-shadow: -1px -2px 4px rgba(245, 175, 151, 0.45),
    2px 2px 4px rgba(173, 115, 95, 0.6);
  color: #fff;
}
.c-sidebar.c-sidebar-custom {
  color: #fff;
  background: #12284c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-right: 1px solid rgba(159, 167, 179, 0.5);
}

.btn-primary-custom {
  color: #fff;
  background-color: #1c4489;
}

.btn-primary-custom:hover {
  background-color: #fff;
  color: #1c4489;
}

.btn-reverse-primary-custom {
  background-color: #e4e7ec;
  color: #1c4489;
}

.btn-reverse-primary-custom:hover {
  color: #fff;
  background-color: #1c4489;
}

.btn-reverse-danger-custom {
  background-color: #e4e7ec;
  color: #e55353;
}

.btn-reverse-danger-custom:hover {
  color: #fff;
  background-color: #e55353;
}

.btn-reverse-warning-custom {
  background-color: #e4e7ec;
  color: #f9b115;
}

.btn-reverse-warning-custom:hover {
  color: #fff;
  background-color: #f9b115;
}

.btn-outline-primary-custom {
  // background-color: #e4e7ec;
  // color: #1c4489;
  color: #1c4489;
  border-color: #1c4489;
}

.btn-outline-primary-custom:hover {
  // color: #fff;
  // background-color: #1c4489;
  color: #fff;
  background-color: #1c4489;
  border-color: #1c4489;
}
.modal.sub-modal{
  z-index: 1001 !important;
}
.on_mode_view{
  input.form-check-input[type=checkbox]{
    pointer-events: none;
    opacity: 0.5;
  }
  input.custom-control-input[type=checkbox]{
    pointer-events: none;
    opacity: 0.5;
  }
  input[type=text]{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: 0.8;
  }
  input[type=date]{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: 0.8;
  }
  input[type=number]{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: 0.8;
  }
  label.custom-control-label.chk-label{
    pointer-events: none;
    opacity: 0.5;
  }
  button{
    pointer-events: none;
    opacity: 0.5;
  }
}
.on_mode_edit_account{
  input.form-check-input[type=checkbox]{
    pointer-events: none;
    opacity: 0.5;
  }
  input.custom-control-input[type=checkbox]{
    pointer-events: none;
    opacity: 0.5;
  }
  input[type=text]{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: 0.8;
  }
  input[type=date]{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: 0.8;
  }
  input[type=number]{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: 0.8;
  }
  label.custom-control-label.chk-label{
    pointer-events: none;
    opacity: 0.5;
  }
  button{
    pointer-events: none;
    opacity: 0.5;
  }
  .tab-accounting{
    input.form-check-input[type=checkbox]{
      pointer-events: auto;
      opacity: 1;
    }
    input.custom-control-input[type=checkbox]{
      pointer-events: auto;
      opacity: 1;
    }
    input[type=text]{
      -webkit-user-select: auto;
      -moz-user-select: auto;
      -ms-user-select: auto;
      user-select: auto;
      pointer-events: auto;
      opacity: 1;
    }
    input[type=date]{
      -webkit-user-select: auto;
      -moz-user-select: auto;
      -ms-user-select: auto;
      user-select: auto;
      pointer-events: auto;
      opacity: 1;
    }
    input[type=number]{
      -webkit-user-select: auto;
      -moz-user-select: auto;
      -ms-user-select: auto;
      user-select: auto;
      pointer-events: auto;
      opacity: 1;
    }
    label.custom-control-label.chk-label{
      pointer-events: auto;
      opacity: 1;
    }
    button{
      pointer-events: auto;
      opacity: 1;
    }
  }
  .tab-costing{
    input.form-check-input[type=checkbox]{
      pointer-events: auto;
      opacity: 1;
    }
    input.custom-control-input[type=checkbox]{
      pointer-events: auto;
      opacity: 1;
    }
    input[type=text]{
      -webkit-user-select: auto;
      -moz-user-select: auto;
      -ms-user-select: auto;
      user-select: auto;
      pointer-events: auto;
      opacity: 1;
    }
    input[type=date]{
      -webkit-user-select: auto;
      -moz-user-select: auto;
      -ms-user-select: auto;
      user-select: auto;
      pointer-events: auto;
      opacity: 1;
    }
    input[type=number]{
      -webkit-user-select: auto;
      -moz-user-select: auto;
      -ms-user-select: auto;
      user-select: auto;
      pointer-events: auto;
      opacity: 1;
    }
    label.custom-control-label.chk-label{
      pointer-events: auto;
      opacity: 1;
    }
    button{
      pointer-events: auto;
      opacity: 1;
    }
  }
}